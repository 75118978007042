import Posts from "./components/posts";
import ModalForm from "./components/modalform";

// https://dev.to/kimmese/react-bootstrap-modal-form-31gc
function App() {
  return (
    <div>
      <Posts />
    </div>
  );
}

export default App;
