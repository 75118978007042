import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import ModalForm from "./modalform";

const postsUrl = "https://worker-hiring.theoo.workers.dev/posts";

const Posts = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const getPosts = async () => {
      fetch(postsUrl)
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            throw new Error("Error fetching posts");
          }
        })
        .then((postsJson) => {
          setPosts(postsJson);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getPosts();
  }, []);

  const deletePost = async (title) => {
    console.log("Deleting post", title);

    const requestOptions = {
      method: "DELETE",
      body: title,
    };
    fetch(postsUrl, requestOptions)
      .then((response) => console.log("Submitted successfully"))
      .catch((error) => console.log("Form submit error", error));

    setPosts(
      posts.filter(function (post) {
        return post.title !== title;
      })
    );
  };

  const onPostChange = (jsoncontent) => {
    setPosts(posts.concat(jsoncontent));
  };

  return (
    <div>
      <h1> Posts </h1>{" "}
      {posts.map((post, index) => (
        <Card key={index}>
          <Card.Body>
            <Card.Title>{post.title}</Card.Title>
            <Card.Subtitle>By: {post.username}</Card.Subtitle>
            <Card.Text>{post.content}</Card.Text>
          </Card.Body>
          <Button
            onClick={(event) => {
              event.preventDefault();
              deletePost(post.title);
            }}
          >
            Delete Post
          </Button>
        </Card>
      ))}{" "}
      <ModalForm onPostChange={onPostChange} />
    </div>
  );
};

export default Posts;
