import { useState } from "react";
import Form from "react-bootstrap/Form";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ModalForm = ({ onPostChange }) => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const submitForm = async (event) => {
    event.preventDefault();

    console.log("Submitting your form");
    const url = "https://worker-hiring.theoo.workers.dev/posts";
    const jsoncontent = {
      title: form.title,
      username: form.username,
      content: form.content,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(jsoncontent),
    };
    fetch(url, requestOptions)
      .then((response) => console.log("Submitted successfully"))
      .catch((error) => console.log("Form submit error", error));

    handleClose();
    onPostChange(jsoncontent);
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Launch post creation form
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create New Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitForm}>
            <Form.Group className="mb-3" controlId="postForm.ControlTitle">
              <Form.Label>Title</Form.Label>
              <Form.Control
                onChange={(e) => setField("title", e.target.value)}
                placeholder="Enter title"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="postForm.ControlUsername">
              <Form.Label>username</Form.Label>
              <Form.Control
                onChange={(e) => setField("username", e.target.value)}
                placeholder="Enter username"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlContent">
              <Form.Label>Content</Form.Label>
              <Form.Control
                onChange={(e) => setField("content", e.target.value)}
                as="textarea"
                rows={5}
              />
            </Form.Group>

            <Button type="submit">Submit</Button>
          </Form>{" "}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalForm;
